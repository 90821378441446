import React from 'react'
import { Link } from 'gatsby'
import Container from '../components/Container'
import LBNavigation from '../components/LBNavigation'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = () => (
<div className="pages-container">
<LBNavigation isHome={false}/>
<Layout pageClass="pages-page">
<SEO title="404" description="Page Not Found" />
  <div className="title-pages-wrapper">
    <h1 className="title-pages">Page Not Found</h1>
  </div>
  <Container>
      <div className="pages-body text-styles black-layout page-404">
        Please return <Link to="/">home</Link> or use the menu to navigate to a
        different page.
      </div>
  </Container>
</Layout>
</div>
)

export default NotFoundPage
